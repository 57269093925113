<template>
  <div class="v_change_bank">
    <head-back>
      <template v-slot:returnText>
        <span>返回</span>
      </template>

        <template v-slot:default>
        <span>支付宝</span>
      </template>
    </head-back>

    <div class="v-change-info-content">
      <div class="v-change-info-form">
        <div class="v-change-info-form-item">
          <!--  :readonly="$global.userInfo.name ? true: false" -->
           <van-field v-model="form.name" label="支付宝姓名:" placeholder="请输入姓名" clearable/>
        </div>
        <div class="v-change-info-form-item">
           <van-field v-model="form.card_number" label="支付宝账号:" placeholder="请输入支付宝账号" clearable/>
        </div>
      </div>
    </div>


    <div class="v-change-info-btn">
      <operation-button @clickItem="emitClickItem" :buttonObj="buttonObj"></operation-button>
    </div>
  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import OperationButton from '../../components/OperationButton.vue'
  import { apiBindBnk, apiGetUserInfo, apiChangeBank } from '@/utils/api.js'
  export default {
    components: { HeadBack, OperationButton },
    data() {
      return {
        value: '',
        bankValue: '请选择银行',
        form: {
          type: 2,
          bank_name: '支付宝',
          name: '',
          card_number: ''
        },

        readOnly: true,


        buttonObj: {
          claSS: 'c-btn-default',
          title: '确认提交',
          event: 'submitBankClick'
        },
      }
    },
    created() {
      if(this.$global.userInfo != {}) {
        if(this.$global.userInfo.alipay) {
          let copyObj = JSON.parse(JSON.stringify(this.$global.userInfo.alipay))
          this.form = copyObj
        }
      }
    },
    methods: {
      submitBankClick() {
        if(this.$global.userInfo != {}) {
          if(!this.$global.userInfo.alipay) return this.apiBindBnkHandel()
          return this.apiChangeBankHandel()
        }
      },

      // 绑定支付宝
      async apiBindBnkHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiBindBnk(this.form)
        if(!success) return
        this.$toast(data.msg)
        this.apiGetUserInfoHandel()
      },

      // 修改支付宝/银行卡
      async apiChangeBankHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiChangeBank(this.form)
        if(!success) return
        this.$toast(data.msg)
        this.apiGetUserInfoHandel()
      },

      // 获取用户信息
      async apiGetUserInfoHandel() {
        const { success, data } = await apiGetUserInfo()
        if(!success) return
        this.$global.setUserInfo(data.data)
      },

      bankClick() {
        this.$refs.ChangeBank.onShow(true)
      },

      emitClickItem(event) {
        if(!event) return
        this[event]()
      },

      submitClick() {
        console.log('提交')
      }
    }
  }
</script>

<style lang="scss">
.v_change_bank {
  height: 100%;
  background: #F1F1F1;
  .v-change-info-content {
    padding: 10px;
    .v-change-info-form {
      background: $white;
      border-radius: 8px;
      overflow: hidden;
      .v-change-info-form-item {
        .v-change-info-form-item-box {
          padding: 10px 16px;
          .v-change-info-form-item-label {
            flex: 1;
            color: #646566;
            text-align: left;
            font-size: 14px;
          }
          .van-radio-group {
            flex: 2;
            font-size: 14px;
          }
        }

        .van-cell {
          .van-cell__title  {
            margin-right: 0;
            flex: 1;
            color: #646566;
          }
          .van-cell__value {
            flex: 2;
            text-align: left;
            .van-field__control {
              color: #969799;
            }
          }
        }

        .v-change-info-form-item-date-cell {
          .van-cell__value {
            padding-left: 10px;
          }
        }
      }
    }
  }
  .v-change-info-btn {
    margin-top: 20px;
  }
}
</style>